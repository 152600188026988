import React from 'react';
import Layout from '../components/layout/Layout';
import { BackButton } from '../components/ButtonDesign';
import { Link } from 'gatsby';
import "../style/styles.css"

const about = () => (
  <Layout>
    <div className="w-full h-full px-4 lg:px-10 md:px-32 py-6 mx-auto mt-8 mb-2 lg:mt-0 lg:mb-0 text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center flex justify-center items-center">
      <div className="">
        <div className="w-full my-8 mb-4">
          <p className="text-lg text-left border-b-2">どんなサイト？ What kind of site?</p>
          <div className="py-4 sm:p-8 text-sm md:text-xl text-left break-all">
            <p className="mb-2">Monaparty上で遊べるトークン"XMP"が貰えるサイトです。24時間ごとに20XMPが貰えます。</p>
            <p>It is a site where you can get the token "XMP" that you can play on Monaparty. You will receive the following amount of XMP every 24 hours.</p>
            {/* <div className="my-8 border-2 text-center">
              <table className="w-full table-fixed border-collapse border">
                <thead>
                  <tr>
                    <th className="py-2 bg-pink-400 w-full border">Mpurse を ... &#x1f98d;</th>
                  </tr>
                </thead>
              </table>
              <table className=" w-full table-fixed border-collapse border">
                <thead>
                  <tr>
                    <th className="py-2 bg-pink-400 w-1/2 border">使用する</th>
                    <th className="py-2 bg-pink-400 w-1/2 border">使用しない</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-2 bg-pink-400 border line-through">50XMP<p className='no-underline inline-block'>10XMP</p></td>
                    <td className="py-2 bg-pink-400 border line-through">30XMP<p className='no-underline inline-block'>10XMP</p></td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
          <div className="mb-4">
            <p className="text-lg text-left border-b-2">XMP？ Monaparty？ Mpurse？</p>
            <div className="py-4 sm:p-8 text-sm md:text-xl text-left break-all">
              <Link to="/xmp">・XMPはこちらのページを見てね。&#x1f448;</Link><br />
              <Link to="/mpurse">・Mpurseはこちらのページ&#x1f448;</Link>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-lg text-left border-b-2">ありがとうございます</p>
            <div className="py-4 sm:p-8 text-sm md:text-xl text-left break-all">
              <p>当サイトは各ウォレットの利用、外部の情報を参照することで成立しています。これらを開発、維持をして頂きありがとうございます。</p>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-lg text-left border-b-2">利用に関する注意事項</p>
            <div className="py-4 sm:p-8 text-sm md:text-xl text-left break-all">
              <p>当サイトでは利用者の秘密鍵を預かりません。そのため、秘密鍵の紛失、及びその他のトラブルにおいて対応出来ません。また、当サイト上で発生した不具合、トラブル等に対して一切責任を負いません。当サイトは、予告なくサービスの全部、または一部を変更し、または終了することがあります。</p>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-lg text-left border-b-2">つくった人</p>
            <div className="py-4 sm:p-8 text-sm md:text-xl text-left break-all">
              <a className="hover:opacity-75" href="https://twitter.com/7101kerokero">コタロ</a>
            </div>
          </div>
          <div>
            <p className="text-lg text-left border-b-2">DONATE</p>
            <div className="py-4 sm:p-8 w-full text-left  mb-4  break-all text-sm md:text-xl">
              <div className="w-full">
                <div>
                  <p className="p-1 ">&#x1f5fb; mona</p>
                  <p className="p-1 ">MCVRBQEnDrPVzqYVkchLrPGvLpHG9AhL1r</p>
                </div>
                <div>
                  <p className="p-1 ">&#x1f5fb; btc</p>
                  <p className="p-1 ">bc1qpz64zqhcp2gfm8xyhxct39pmhpwcf7xa2xfh9n</p>
                </div>
                <div>
                  <p className="p-1 ">&#x1f5fb; eth</p>
                  <p className="p-1 ">0xf6721444dB4ADf853FA48b5b0AD68c73e31996b5</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackButton />
      </div>
    </div>
  </Layout>
);

export default about;